const sidebar = document.getElementById("sidebar");
const overlay = document.getElementById("overlay");
const subLevels = document.getElementsByClassName("sub-levels");

function toggleSidebar() {
  sidebar.classList.toggle("open");
  overlay.classList.toggle("do");
}

function openSubLevel(e) {
  const parent = e.parentNode;
  const id = parent.getAttribute("id");
  const ico = parent.getElementsByTagName("svg")[0];
  const subLevel = parent.getElementsByTagName("ul")[0];
  const closing = Array.from(subLevels).filter((o) => o.id !== id);

  for (let i = 0; i < closing.length; i++) {
    closing[i].getElementsByTagName("ul")[0].classList.add("max-h-0");
    closing[i].getElementsByTagName("ul")[0].classList.remove("max-h-fit");
    closing[i].getElementsByTagName("svg")[0].classList.remove("rotate-180");
  }
  subLevel.classList.toggle("max-h-0");
  subLevel.classList.toggle("max-h-fit");
  ico.classList.toggle("rotate-180");
}


function openVCSubLevel(e) {
  
  const parent = e.parentNode;
  const id = parent.getAttribute("id");
  const ico = parent.getElementsByTagName("svg")[0];
  const subLevel = parent.parentNode.getElementsByTagName("ul")[0];
  const closing = Array.from(subLevels).filter((o) => o.id !== id);

  for (let i = 0; i < closing.length; i++) {
    closing[i].getElementsByTagName("ul")[0].classList.add("max-h-0");
    closing[i].getElementsByTagName("ul")[0].classList.remove("max-h-fit");
    closing[i].getElementsByTagName("svg")[0].classList.remove("rotate-180");
  }
  subLevel.classList.toggle("max-h-0");
  subLevel.classList.toggle("max-h-fit"); 
  ico.classList.toggle("rotate-180");
}
 